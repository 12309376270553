import React from "react";
import { UserAuth } from '../../context/AuthContext';
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
    const { user } = UserAuth();
//   const isAuthenticated = localStorage.getItem("isAuthenticated");
  //   console.log("this", isAuthenticated);

  if (user) {
    // console.log("on True");
    return <Route {...restOfProps} component={Component} />;
  }

  return <Redirect to="/" />;
  //   return isAuthenticated == false ? (
  //     <Route {...restOfProps} component={Component} />
  //   ) : (
  //     <Redirect to="/login" />
  //   );

  // <Redirect to="/login" />
  // <Route
  //   {...restOfProps}
  //   render={(props) =>
  //     isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
  //   }
  // />
}

export default ProtectedRoute;
