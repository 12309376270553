import { useState, useEffect } from "react";
import axios from "axios";
import { PuffLoader } from "react-spinners";
import Lesson from "./Lesson";

const LessonCard = ({
  userId,
  studentId,
  gradeId,
  subjectId,
  chapterId,
  lessonData,
  deleteCallback,
  markCompleteCallback
}) => {
  // State to track whether lessons are expanded or collapsed
  const [isExpanded, setIsExpanded] = useState(false);
  const [isComplete, setIsComplete] = useState(lessonData?.isComplete ?? false);

  
  // Toggle expand/collapse state
  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  // Handle delete
  const handleDelete = () => {
    // Call the parent callback function and pass all necessary props
    deleteCallback({
      userId,
      studentId,
      gradeId,
      subjectId,
      chapterId,
      lessonId: lessonData.id,
    });
  };

  

  const handleCheckboxChange = () => {
    const newIsComplete = !isComplete; // Calculate the new state
    setIsComplete(newIsComplete); // Update the state
    markCompleteCallback({
      userId,
      studentId,
      gradeId,
      subjectId,
      chapterId,
      lessonId: lessonData.id,
      isComplete: newIsComplete, // Use the new state
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "15px",
          padding: "5px",
          borderRadius: "10px", // Rounded corners
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          color: "black", // Ensures text is readable
          fontFamily: "Acme, cursive", // Font style
          transition: "box-shadow 0.3s, background-color 0.3s, color 0.3s",
        }}
        onMouseEnter={(e) => {

          if (isExpanded == false) {
            e.currentTarget.style.boxShadow = `
          0 0 4px #ff0080, 
          0 0 4px #ffbf00, 
          0 0 4px #80ff00, 
          0 0 16px #00ffff, 
          0 0 12px #ff00ff
`;
          }
        }}

        onMouseLeave={(e) => {
          e.currentTarget.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.2)";
        }}

      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "5px",
            borderRadius: "10px",
            cursor: "pointer",
            flexDirection: "row"
          }}

        >
          <div onClick={toggleExpand} style={{ display: "flex", flex: 5, ...(isExpanded ? { justifyContent: 'center' } : {}), }}>
            <span style={{ fontFamily: "Acme, cursive", ...(isExpanded ? { fontSize: "25px" } : {fontSize: "15px"}),  cursor: "pointer", color: isComplete ? "green" : "black", }} >
              Lesson {lessonData.order}: {lessonData.name}
            </span>
          </div>
          <div>

            <div style={{ display: "flex", gap: "20px" }}>
              <input
                type="checkbox"
                checked={isComplete}
                onChange={handleCheckboxChange}
                style={{ cursor: "pointer", accentColor: isComplete ? "green" : "black", }}
                title={isComplete ? "Mark Incomplete" : "Mark Complete"}
              />
              <span
                style={{
                  fontFamily: "Acme, cursive",
                  fontSize: "12px",
                  color: isComplete ? "green" : "black",
                }}
              >
                {new Date(lessonData.start).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
              <i
                className="menu-icon fa fa-trash"
                style={{ fontSize: "15px", color: "gray", cursor: "pointer" }}
                title="Delete this Topic"
                onClick={handleDelete}
              ></i>
              <i
                className={`menu-icon fa ${isExpanded ? "fa-chevron-up" : "fa-chevron-down"}`}
                style={{ fontSize: "15px", color: "gray", cursor: "pointer" }}
                title={isExpanded ? "Collapse Lesson" : "Expand Lesson"}
                onClick={toggleExpand}
              ></i>
            </div>
          </div>
        </div>




        {isExpanded && (<div style={{ marginLeft: '10px' }}>
          <Lesson
            userId={userId}
            childId={studentId}
            gradeId={gradeId}
            subjectId={subjectId}
            chapterId={chapterId}
            lessonId={lessonData.id}
          />
        </div>)}

      </div>
    </>


  );
};

LessonCard.defaultProps = {
  cardTitle: "Card Title",
  cardDescription: "Card Description",
  numOfColumns: 3, //default set to 3. Possible 1,2,3...,12
  buttonStyle: "primary",
  indexingTaskId: null,
};

export default LessonCard;
