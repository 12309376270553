import React, { useState } from 'react';
import axios from "axios";

const ContextualChat = ({context}) => {
    const [chatInput, setChatInput] = useState('');
    const [history, setHistory] = useState([
        {
            role: 'user', parts: `You are an virtual assistant to me. Given the following as context. 
        
        Context
        ${context}
        ` }
    ]);

    // Handle chat input submission
    const handleSubmitChat = async (e) => {
        e.preventDefault();
        if (chatInput.trim()) {
            const newHistory = [
                ...history,
                { role: 'user', parts: chatInput },
            ];
            setHistory(newHistory);  // Update history with user's message
            setChatInput('');  // Clear chat input after sending
            try {
                const response = await axios.post("/api/sendChat", {
                    history: newHistory,
                });

                const modelResponse = response.data.model;
                setHistory([
                    ...newHistory,
                    { role: 'model', parts: modelResponse },
                ]);
            } catch (error) {
                console.error('Error sending chat:', error);
            }

            
        }
    };

    return (

        <>
            <div style={{
                display:"flex",
                flexDirection: "column"
        
        }}>
                {history.slice(1).map((message, index) => {
                    // Define color mapping for roles
                    const roleColors = {
                        user: '#d1e7ff', // Light blue for user
                        system: '#f8d7da', // Light red for system
                        assistant: '#d4edda', // Light green for assistant
                    };

                    // Default to gray if role is not defined in the color mapping
                    const backgroundColor = roleColors[message.role] || '#f0f0f0';

                    return (
                        <div
                            key={index}
                            style={{
                                backgroundColor,
                                padding: '3px',
                                borderRadius: '5px',
                                margin: '5px 0',
                                width:"50%",
                                display:"flex",
                                alignSelf:"center"
                            }}
                        >
                            <>
                                <div>
                                    {message.parts}
                                </div>

                                {/* <div
                                    style={{ padding: '30px', margin: '10px' }}
                                    dangerouslySetInnerHTML={{ __html: message.parts }}
                                /> */}
                            </>
                            {/* {message.parts} */}
                        </div>
                    );
                })}

            </div>


            <div>
                <form
                    onSubmit={handleSubmitChat}
                    style={{
                        // position: 'fixed',
                        bottom: 0,
                        left: 0,
                        transform: 'translateX(20%)',
                        width: '70%',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        backgroundColor: '#fff',
                        padding: '10px',
                        // boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
                        zIndex: 1000,
                        borderRadius: 10,
                        marginTop: "5px"
                    }}
                >
                    <input
                        type="text"
                        value={chatInput}
                        onChange={(e) => setChatInput(e.target.value)}
                        placeholder="Chat for any assistance ..."
                        required
                        style={{
                            flex: 1,
                            padding: '8px',
                            border: '1px solid #ddd',
                            borderRadius: '4px'

                        }}
                    />
                    <button
                        type="submit"
                        style={{
                            padding: '8px 16px',
                            backgroundColor: 'black',
                            color: 'white',
                            border: 'none',
                            borderRadius: '3px',
                            cursor: 'pointer'
                        }}
                    >
                        <i
                            className="menu-icon fa fa-location-arrow"
                            style={{ fontSize: "15px", color: "white", cursor: "pointer" }}
                            title="Send Msg"
                        ></i>
                    </button>
                </form>
            </div>
        </>
        // </div></div></div>
    );
};




export default ContextualChat;
