import { useState, useEffect } from "react";
import Header from "../../components/Header";
import HomeLeftPanel from "../../components/HomeLeftPanel";
import { UserAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom"; // for routing

function AiToolBox() {
    const navigate = useHistory();
    const { logOut, user } = UserAuth();

    //get icons from: https://emojipedia.org/
    const tools = [
        {
            id: "text-leveler",
            name: "Text Leveler",
            icon: "📊", // Replace with actual icon components if available
            shortDescription: "Adjust text complexity to match your needs.",
        },
        {
            id: "text-proofreader",
            name: "Text Proofreader",
            icon: "✏️",
            shortDescription: "Catch errors and improve text accuracy.",
        },
        {
            id: "text-translator",
            name: "Text Translator",
            icon: "🌍",
            shortDescription: "Translate text into multiple languages.",
        },
        {
            id: "create-lesson",
            name: "Create Lesson",
            icon: "📚",
            shortDescription: "Generate engaging lessons effortlessly.",
        },
        {
            id: "text-rewriter",
            name: "Text Rewriter",
            icon: "🔄",
            shortDescription: "Rephrase text to enhance readability.",
        },
        {
            id: "youtube-chat",
            name: "YouTube Chat",
            icon: "💬",
            shortDescription: "Analyze and manage YouTube chat comments.",
        },
        {
            id: "youtube-quizmaker",
            name: "YouTube Quiz Maker",
            icon: "📽️",
            shortDescription: "Generate guiding questions aligned to a YouTube video.",
        },
        {
            id: "worksheet-generator",
            name: " Worksheet Generator",
            icon: "📓",
            shortDescription: "Generate a worksheet based on any topic or text.",
        },
        {
            id: "learn-by-doing",
            name: "Learn By Doing",
            icon: "💪",
            shortDescription: "Generate some real life tasks or projects based on a particular lesson.",
        },
        {
            id: "why-study-this",
            name: "Life Skills Connector",
            icon: "🤔",
            shortDescription: "Get inspired by knowing why this topic is important and where one would use or apply it in real life.",
        },
        {
            id: "yt-video-summarizer",
            name: "YouTube Video Summarizer",
            icon: "📽️",
            shortDescription: "Get a summary of a YouTube video in whatever length you choose.",
        },
        {
            id: "common-misconceptions",
            name: "Common Misconceptions",
            icon: "🤷‍♂️",
            shortDescription: "Generate the most common misconceptions and how to address them on any topic.",
        },
        {
            id: "unlock-fun-facts",
            name: "Unlock Fun Facts",
            icon: "💡",
            shortDescription: " Explore fun facts and hidden gems on any topic making learning more engaging and enjoyable!",
        },
        {
            id: "quote-of-the-day",
            name: "Quote Of The Day",
            icon: "📜",
            shortDescription: "Generate quote of the day suggestions based on any topic.",
        },
        {
            id: "definition-generators",
            name: "Definition Generators",
            icon: "📖✨",
            shortDescription: "Generate quote of the day suggestions based on any topic.",
        },
        {
            id: "group-work-generator",
            name: "Group Work Generator",
            icon: "🤝",
            shortDescription: "Generate group work activity for students based on a a topic, standard, or objective.",
        },
        {
            id: "glossary-generator",
            name: "GlossaryGenerator",
            icon: "📖",
            shortDescription: "Generate a list of vocabulary words based on a subject, topic, or text that are important for students to learn.",
        },
        {
            id: "flashcard-generator",
            name: "Flashcard Generator",
            icon: "📑",
            shortDescription: "Create interactive flashcards tailored to any subject or concept, helping students learn efficiently through multiple-choice, true/false, or open-ended questions.",
        },
    ];

    const handleClick = (toolId) => {
        navigate.push(`/ai-tool/${toolId}`);
    };

    return (
        <div id="right-panel" className="right-panel" style={{ backgroundColor: "white" }}>
            <div className="content">
                <div className="animated fadeIn">
                    <Header />
                    <HomeLeftPanel currentPage={"topics"} />
                    <span style={{textAlign:"center", fontFamily:"Acme", fontSize:"20px"}}>AI Tools</span>
                    <hr/>
                    <div className="tools-container" style={styles.toolsContainer}>
                        {tools.map((tool) => (
                            <div
                                key={tool.id}
                                className="tool-card"
                                style={styles.toolCard}
                                onClick={() => handleClick(tool.id)}
                            >
                                <div className="tool-icon" style={styles.toolIcon}>
                                    {tool.icon}
                                </div>
                                <div className="tool-info" style={styles.toolInfo}>
                                    <h4 style={styles.toolName}>{tool.name}</h4>
                                    <p style={styles.toolDescription}>{tool.shortDescription}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    toolsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        padding: "20px",
        justifyContent: "flex-start",
    },
    toolCard: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        padding: "15px",
        borderRadius: "8px",
        backgroundColor: "#fff",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
        width: "300px",
        transition: "transform 0.2s",
    },
    toolCardHover: {
        transform: "scale(1.05)",
    },
    toolIcon: {
        fontSize: "30px",
    },
    toolInfo: {
        display: "flex",
        flexDirection: "column",
    },
    toolName: {
        fontSize: "18px",
        fontWeight: "bold",
        margin: "0 0 5px",
    },
    toolDescription: {
        fontSize: "14px",
        color: "#666",
        margin: 0,
    },
};

export default AiToolBox;
