import { UserAuth } from "../context/AuthContext";
import mySchoolsIcon from "../components/assets/images/edusinaIcons/My school.png";
import toolsIcon from "../components/assets/images/edusinaIcons/Tools.png";
import communityIcon from "../components/assets/images/edusinaIcons/community.png";
import curLessonsIcon from "../components/assets/images/edusinaIcons/Curriculum & Lessons (5).png";
import scheduleIcon from "../components/assets/images/edusinaIcons/Schedule (2).png";
import activitiesIcon from "../components/assets/images/edusinaIcons/Activities.png";
import adminIcon from "../components/assets/images/edusinaIcons/Adminstration (2).png";
import resIcon from "../components/assets/images/edusinaIcons/Resources.png";

const StudentHomeLeftPanel = ({ currentPage, studentId, studentName }) => {
  const { user } = UserAuth();
  console.log(user);
  return (
    <aside id="left-panel" className="left-panel">
      <nav className="navbar navbar-expand-sm navbar-default">
        <br />
        <div id="main-menu" className="main-menu collapse navbar-collapse">
          <ul className="nav navbar-nav">
          <li class="menu-title" style={{ fontFamily: "Acme, cursive", fontSize: '20px' }}>Home</li>
            
          <li className={currentPage === 'home' ? 'active' : ''}>
              <a href="/home" style={{ fontFamily: "Acme, cursive", fontSize: '18px' }}>
                <img src={mySchoolsIcon} style={{height:"35px", width:"35px"}}/> My Schools
              </a>
            </li>
            <li class="">
              <a href="/ai-toolbox" style={{ fontFamily: "Acme, cursive", fontSize: '18px' }}><img src={toolsIcon} style={{height:"35px", width:"35px"}}/>AI Tools</a>
            </li>
            <li class="">
              <a href="#" style={{ fontFamily: "Acme, cursive", fontSize: '18px' }}><img src={communityIcon} style={{height:"35px", width:"35px"}}/>Community <i className="fa fa-lock" style={{marginLeft:'5px'}}></i></a> 
            </li>


            {/* <br/> */}

            <li class="menu-title" style={{ marginTop: "50px", fontFamily: "Acme, cursive", fontSize: '20px' }}>{studentName}'s School</li>

            <li className={currentPage === 'curriculum' ? 'active' : ''} >
              <a href={`/student/${studentId}`} style={{ fontFamily: "Acme, cursive", fontSize: '18px' }}><img src={curLessonsIcon} style={{height:"35px", width:"35px"}}/>Curriculum & Lessons</a>
            </li>
            <li className={currentPage === 'schedule' ? 'active' : ''}>
              <a href={`/schedule/${studentId}`} style={{ fontFamily: "Acme, cursive", fontSize: '18px' }}><img src={scheduleIcon} style={{height:"35px", width:"35px"}}/>Schedule</a>
            </li>
            <li class="">
              <a href="#" style={{ fontFamily: "Acme, cursive", fontSize: '18px' }}><img src={activitiesIcon} style={{height:"35px", width:"35px"}}/>Activities <i className="fa fa-lock" style={{marginLeft:'5px'}}></i></a>
            </li>
            <li class="">
              <a href="#" style={{ fontFamily: "Acme, cursive", fontSize: '18px' }}><img src={adminIcon} style={{height:"35px", width:"35px"}}/>Adminstration <i className="fa fa-lock" style={{marginLeft:'5px'}}></i></a>
            </li>
            <li class="">
              <a href="#" style={{ fontFamily: "Acme, cursive", fontSize: '18px' }}><img src={resIcon} style={{height:"35px", width:"35px"}}/>Resources <i className="fa fa-lock" style={{marginLeft:'5px'}}></i></a>
            </li>


          </ul>

        </div>
      </nav>
    </aside>
  );
};

export default StudentHomeLeftPanel;
