import React, { useState, useEffect } from "react";
import axios from "axios";
import { PuffLoader } from "react-spinners";
import ContentModal from "./ContentModal";

const Assignments = ({ userId, childId, gradeId, subjectId, chapterId, lessonId }) => {
  const [lessonData, setLessonData] = useState(null);
  const [assessments, setAssessments] = useState(null); // Updated to differentiate loading states
  const [expandedItems, setExpandedItems] = useState({});
  const [modalType, setModalType] = useState(null);
  const [refreshCount, setRefreshCount] = useState(1);

  useEffect(() => {
    const fetchLesson = async () => {
      try {
        const response = await axios.get(
          `/api/lesson/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-tokens": userId,
            },
          }
        );
        setLessonData(response.data);
      } catch (err) {
        console.error(err.message || "An error occurred while fetching the lesson.");
      }
    };

    const fetchAssessments = async () => {
      try {
        const response = await axios.get(
          `/api/lesson/assessments/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-tokens": userId,
            },
          }
        );
        setAssessments(response.data || []);
      } catch (err) {
        console.error(err.message || "An error occurred while fetching assessments.");
        setAssessments([]);
      }
    };

    fetchLesson();
    fetchAssessments();
  }, [childId, gradeId, subjectId, chapterId, lessonId, userId, refreshCount]);

  const toggleItem = (index) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleOpenModal = (type) => {
    setModalType(type);
  };

  const handleCloseModal = () => {
    setModalType(null);
  };

  const onAssessmentUpdate = () => {
    setRefreshCount(refreshCount+1);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* Render assessments */}
      {assessments === null ? ( // Show loader while data is being fetched
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <PuffLoader color="#ff602e" loading={true} size={25} />
          <p>Loading assessments...</p>
        </div>
      ) : assessments.length === 0 ? ( // Show message if no assessments
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <p>No assessment added yet for the lesson</p>
        </div>
      ) : (
        Object.entries(assessments).map(([key, assessment], index) => (
          <div
            key={key}
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "40px",
              cursor: "pointer",
            }}
            onClick={() => toggleItem(key)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "30px",
                padding: "3px",
                marginTop: "15px",
                transition: "box-shadow 0.3s, background-color 0.3s, color 0.3s",
                boxShadow: "2px 2px 2px rgba(0, 0, 0, 0)",
                // width: "40%",
                borderRadius: "5px",
                fontSize: "14px",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow = `
                0 0 4px #ff0080, 
                0 0 8px #ffbf00, 
                0 0 12px #80ff00, 
                0 0 16px #00ffff, 
                0 0 20px #8000ff
                `;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "0 0 0px rgba(0, 0, 0, 0)";
              }}
            >
              {/* <span>{index + 1}</span> */}
              <span>Assessment {index + 1} ({assessment.type})</span>
              {/* <span>Type: {assessment.type}</span> */}
              <span>Due Date: {new Date(assessment.due_date).toLocaleDateString()}</span>
            </div>
            {expandedItems[key] && (
              <div style={{ marginTop: "10px", paddingLeft: "20px", color: "#555" }}>
                <strong>Content:</strong>
                <div dangerouslySetInnerHTML={{ __html: assessment.content }} />
              </div>
            )}
          </div>
        ))
      )}

      {/* Buttons to open modals */}
      <div style={{ display: "flex", flexDirection: "column", padding: "40px 40px 15px" }}>
        <span style={{ textDecoration: "underline" }}>+ Add New</span>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {["Assignment", "Quiz", "Flashcard"].map((type) => (
            <span
              key={type}
              style={{
                cursor: "pointer",
                width: "100px",
                padding: "3px",
                borderRadius: "8px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                backgroundColor: "black",
                textAlign: "center",
                border: "1px solid #ccc",
                display: "inline-block",
                color: "white",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow = `
                0 0 4px #ff0080, 
                0 0 8px #ffbf00, 
                0 0 12px #80ff00, 
                0 0 16px #00ffff, 
                0 0 20px #8000ff
                `;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "0 0 0px rgba(0, 0, 0, 0)";
              }}
              onClick={() => handleOpenModal(type.toLowerCase())}
            >
              {type}
            </span>
          ))}
        </div>
      </div>

      {/* Modal */}
      {lessonData && (
        <ContentModal
          type={modalType}
          isOpen={!!modalType}
          onClose={handleCloseModal}
          
          userId={userId}
          childId={childId}
          gradeId={gradeId}
          subjectId={subjectId}
          chapterId={chapterId}
          lessonId={lessonId}
          lessonData={lessonData.lesson_text}
          onAssessmentUpdate={onAssessmentUpdate}
        />
      )}
    </div>
  );
};

export default Assignments;
