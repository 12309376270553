import { UserAuth } from "../context/AuthContext";
import mySchoolsIcon from "../components/assets/images/edusinaIcons/My school.png";
import toolsIcon from "../components/assets/images/edusinaIcons/Tools.png";
import communityIcon from "../components/assets/images/edusinaIcons/community.png";
import createNewSchoolIcon from "../components/assets/images/edusinaIcons/Tools (2).png";

const HomeLeftPanel2 = ({ currentPage }) => {
  const { user } = UserAuth();
  console.log(user);
  return (
    <aside id="left-panel" className="left-panel">
      <nav className="navbar navbar-expand-sm navbar-default">
        <br />
        <div id="main-menu" className="main-menu collapse navbar-collapse">
          <ul className="nav navbar-nav">
            <li className="menu-title" style={{ fontFamily: "Acme, cursive", fontSize: "20px" }}>Home</li>

            <li className={currentPage === 'home' ? 'active' : ''}>
              <a 
                href="/home" 
                style={{ fontFamily: "Acme, cursive", fontSize: '18px' }}
                className="menu-item"
              >
                <img src={mySchoolsIcon} style={{ height: "35px", width: "35px" }} />
                <span>My Schools</span>
              </a>
            </li>

            <li className={currentPage === 'tools' ? 'active' : ''}>
              <a 
                href="/ai-toolbox" 
                style={{ fontFamily: "Acme, cursive", fontSize: '18px' }}
                className="menu-item"
              >
                <img src={toolsIcon} style={{ height: "35px", width: "35px" }} />
                <span>AI Tools</span>
              </a>
            </li>

            <li>
              <a 
                href="#" 
                style={{ fontFamily: "Acme, cursive", fontSize: '18px' }}
                className="menu-item"
              >
                <img src={communityIcon} style={{ height: "35px", width: "35px" }} />
                <span>Community</span> <i className="fa fa-lock" style={{marginLeft:'5px'}}></i>
              </a>
            </li>

            {/* <li style={{ position: "fixed", bottom: "0px" }}>
              <div 
                style={{
                  backgroundColor: "#aa0000",
                  color: "white",
                  fontFamily: "Acme, cursive",
                  fontSize: "16px",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // borderRadius: "5px",
                  textAlign: "center",
                  // marginLeft: "16px"
                }}
              >
               Trial Ends in 29 Days
              </div>
            </li> */}

            <li style={{ position: "fixed", bottom: "0px", width:"280px" }}>
              <a 
                href="/add-new-student" 
                style={{ fontFamily: "Acme, cursive", fontSize: '18px' }}
                className="menu-item"
              >
                <b>+</b>
                <span style={{ marginLeft: "3px" }}> Create New School</span>
              </a>
            </li>

          </ul>

        </div>
      </nav>
      <style jsx>{`
        /* Base styles (unchanged for larger screens) */
        .menu-item {
          display: flex;
          align-items: center;
          gap: 10px;
          font-family: "Acme, cursive";
          font-size: 18px;
          text-decoration: none;
          color: inherit;
          padding: 8px 16px;
          transition: background-color 0.3s ease;
        }
        .menu-item:hover {
          background-color: #f0f0f0;
          border-radius: 5px;
        }
        .menu-icon {
          height: 35px;
          width: 35px;
        }
        .menu-title {
          margin-bottom: 16px;
          font-size: 20px;
        }

        /* Responsive styles */
        @media (max-width: 768px) {
          #left-panel {
            width: 100%;
          }
          .menu-item {
            justify-content: center;
            gap: 5px;
            font-size: 16px;
          }
          .menu-icon {
            height: 30px;
            width: 30px;
          }
        }

        @media (max-width: 480px) {
          .menu-item span {
            display: none;
          }
          .menu-icon {
            margin: 0 auto;
          }
        }
      `}</style>
    </aside>
  );
};

export default HomeLeftPanel2;
