import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Resources = ({ userId, childId, gradeId, subjectId, chapterId, lessonId }) => {
    const [resources, setResources] = useState([]);
    const [youtubeLink, setYoutubeLink] = useState('');
    const [websiteLink, setWebsiteLink] = useState('');
    const [pdfLink, setPdfLink] = useState('');
    const [textContent, setTextContent] = useState('');
    const [textType, setTextType] = useState('header');

    // Fetch lesson data on component mount
    useEffect(() => {
        const fetchLessonData = async () => {
            try {
                const response = await axios.get(
                    `/api/lesson/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-tokens": userId,
                        },
                    }
                );
                if (response.data.resources) {
                    setResources(response.data.resources);
                }
            } catch (error) {
                console.error('Error fetching lesson data:', error);
            }
        };

        fetchLessonData();
    }, [childId, gradeId, subjectId, chapterId, lessonId]);

    // Save lesson resources to Firebase
    const saveResourcesToFirebase = async (updatedResources) => {
        try {
            await axios.put(
                `/api/lesson/resources/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
                { lesson_resources: updatedResources },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-tokens": userId,
                    },
                }
            );
            console.log('Resources updated successfully.');
        } catch (error) {
            console.error('Error updating resources:', error);
        }
    };

    // Add resource
    const addResource = (resource) => {
        const updatedResources = [...resources, resource];
        setResources(updatedResources);
        saveResourcesToFirebase(updatedResources);
    };

    // Delete resource
    const deleteResource = (indexToDelete) => {
        const updatedResources = resources.filter((_, index) => index !== indexToDelete);
        setResources(updatedResources);
        saveResourcesToFirebase(updatedResources);
    };

    const renderResource = (resource, index) => {
        return (
            <div key={index} style={{ ...styles.resourceItem, display: 'flex', position: 'relative' }}>
                <button
                    style={styles.deleteButton}
                    onClick={() => deleteResource(index)}
                >
                    ✖
                </button>
                {resource.type === 'youtube' && (
                    <iframe
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${resource.link.split('v=')[1]}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                )}
                {resource.type === 'website' && (
                    <iframe
                        src={resource.link}
                        width="100%"
                        height="500"
                        frameBorder="0"
                        title="Website Embed"
                    />
                )}
                {resource.type === 'pdf' && <embed src={resource.link} width="100%" height="500" />}
                {resource.type === 'header' && <h6>{resource.content}</h6>}
                {resource.type === 'paragraph' && <p>{resource.content}</p>}
            </div>
        );
    };

    return (
        <div style={{ ...styles.container, flexDirection: 'column', gap: '20px' }}>
            <div style={{ ...styles.inputSection, flexDirection: 'row', gap: '20px' }}>
                <div style={styles.inputGroup}>
                    <input
                        type="text"
                        placeholder="YouTube Video URL"
                        value={youtubeLink}
                        onChange={(e) => setYoutubeLink(e.target.value)}
                        style={styles.input}
                    />
                    <button onClick={() => addResource({ type: 'youtube', link: youtubeLink })} style={styles.button}>
                        Add YouTube
                    </button>
                </div>
                <div style={styles.inputGroup}>
                    <input
                        type="text"
                        placeholder="PDF URL"
                        value={pdfLink}
                        onChange={(e) => setPdfLink(e.target.value)}
                        style={styles.input}
                    />
                    <button onClick={() => addResource({ type: 'pdf', link: pdfLink })} style={styles.button}>
                        Add PDF
                    </button>
                </div>
                <div style={styles.inputGroup}>
                    <input
                        placeholder="Add your text here"
                        value={textContent}
                        onChange={(e) => setTextContent(e.target.value)}
                        style={styles.input}
                    />
                    <button onClick={() => addResource({ type: textType, content: textContent })} style={styles.button}>
                        Add Text
                    </button>
                </div>
            </div>
            <div style={styles.resourcesDisplay}>
                {resources.map((resource, index) => renderResource(resource, index))}
            </div>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        margin: '20px',
        padding: '20px',
    },
    inputSection: {
        display: 'flex',
        gap: '20px',
    },
    inputGroup: {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
    },
    input: {
        flex: 1,
        padding: '1px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        fontSize: '14px',
    },
    button: {
        padding: '1px',
        backgroundColor: '#A9B734',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    resourcesDisplay: {
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    resourceItem: {
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        position: 'relative',
    },
    deleteButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'orange',
        color: 'white',
        border: 'none',
        // borderRadius: '50%',
        cursor: 'pointer',
        padding: '5px',
        width: '30px',
        height: '30px',
        textAlign: 'center',
    },
};

export default Resources;
