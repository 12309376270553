import { useState } from "react";
import LessonCard from "./LessonCard";
import axios from "axios";

const ChapterCard = ({ userId, studentId, gradeId, subjectId, chapterData, deleteCallback }) => {
  // State to track whether lessons are expanded or collapsed
  const [isExpanded, setIsExpanded] = useState(true);

  // Local state to store the current chapterData (to avoid direct prop mutation)
  const [localChapterData, setLocalChapterData] = useState(chapterData);

  // Toggle expand/collapse state
  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

      // Handle delete
      const handleDelete = () => {
        // Call the parent callback function and pass all necessary props
        deleteCallback({
          userId,
          studentId,
          gradeId,
          subjectId,
          chapterId: chapterData.id
        });
      };

  const handleLessonDelete = (data) => {
    // Prompt confirmation before proceeding with the delete action
    const isConfirmed = window.confirm("Are you sure you want to delete this lesson?");
    if (!isConfirmed) {
      return; // If the user cancels, do nothing
    }
  
    // Destructure the necessary properties from the data object
    const { userId, studentId, gradeId, subjectId, chapterId, lessonId } = data;
  
    // Construct the API endpoint
    const url = `/api/lesson/${studentId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`;
  
    // Call the API using axios to delete the lesson
    axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": userId, // Include the userId in the header as shown in the example
        },
      })
      .then((response) => {
        console.log("Lesson deleted successfully:", response.data);
  
        // Remove the deleted lesson from the localChapterData
        const updatedLessons = { ...localChapterData.lessons };
        delete updatedLessons[lessonId];
  
        // Reorder remaining lessons and update their 'order' field
        const reorderedLessons = Object.entries(updatedLessons)
          .sort(([, a], [, b]) => a.order - b.order) // Sort lessons by order
          .reduce((acc, [lessonId, lessonData], index) => {
            // Update the 'order' of each lesson starting from 1
            acc[lessonId] = { ...lessonData, order: index + 1 };
            return acc;
          }, {});
  
        // Update the local state with the reordered lessons
        setLocalChapterData((prevState) => ({
          ...prevState,
          lessons: reorderedLessons,
        }));
      })
      .catch((error) => {
        console.error("Error deleting lesson:", error);
        // Optionally, handle the error (e.g., show an error message to the user)
      });
  };
  

  const handleMarkComplete = (data) => {
    const { userId, studentId, gradeId, subjectId, chapterId, lessonId, isComplete } = data;
    console.log("complete status changed!", isComplete);

    // Construct the API endpoint
    const url = `/api/lesson/${studentId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}/isComplete`;

    try {
      // Make the PUT request using axios
      axios.put(
        url,
        { isComplete: isComplete }, // Request payload
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": userId, // Include the userId in the header
          },
        }
      );
      // console.log(`Lesson completion status updated to: ${newIsComplete}`);
    } catch (error) {
      console.error("Failed to update lesson completion status:", error.response?.data?.message || error.message);
    }

  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "7px",
          padding: "10px",
          borderRadius: "10px",
          color: "black",
          fontFamily: "Acme, cursive",
        }}
      >
<div
  style={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "5px",
    padding: "10px",
    borderRadius: "10px",
  }}
>
  {/* Chapter Name */}
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1, // Ensures the span takes up available space to center properly
    }}
  >
    <span style={{ fontFamily: "Acme, cursive", fontSize: "17px" }}>
      Topic {localChapterData.order}: {localChapterData.name}
    </span>
  </div>

  {/* Icons and Expand Icon */}
  <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
    {/* Delete Icon */}
    <i
      className="menu-icon fa fa-trash"
      style={{ fontSize: "15px", color: "gray", cursor: "pointer" }}
      title="Delete this Topic"
      onClick={handleDelete}
    ></i>

    {/* Add Icon */}
    <i
      className="menu-icon fa fa-plus"
      style={{ fontSize: "15px", color: "gray", cursor: "pointer" }}
      title="Add New Lesson to this Topic"
      onClick={() => alert("Add clicked!")}
    ></i>

    {/* Expand/Collapse Icon */}
    <i
      className={`menu-icon fa ${isExpanded ? "fa-chevron-up" : "fa-chevron-down"}`}
      style={{ fontSize: "15px", color: "gray", cursor: "pointer" }}
      title={isExpanded ? "Collapse Chapter" : "Expand Chapter"}
      onClick={toggleExpand}
    ></i>
  </div>
</div>


        {/* Lessons Section */}
        {isExpanded &&
          Object.entries(localChapterData.lessons || {})
            .sort(([, a], [, b]) => a.order - b.order) // Sort lessons by order
            .map(([lessonId, lessonData]) => (
              <div key={lessonId} style={{ marginLeft: "5px" }}>
                <LessonCard
                  userId={userId}
                  studentId={studentId}
                  gradeId={gradeId}
                  subjectId={subjectId}
                  chapterId={localChapterData.id}
                  lessonData={lessonData}
                  deleteCallback={handleLessonDelete}
                  markCompleteCallback={handleMarkComplete}
                />
              </div>
            ))}
      </div>
    </>
  );
};

ChapterCard.defaultProps = {
  cardTitle: "Card Title",
  cardDescription: "Card Description",
  numOfColumns: 3, // default set to 3. Possible 1,2,3...,12
  buttonStyle: "primary",
  indexingTaskId: null,
};

export default ChapterCard;
