import { useState, useEffect } from "react";
import { format, parseISO, isToday, isYesterday, isTomorrow, compareAsc } from 'date-fns';
import axios from "axios";
// import LeftPanel from "../../components/LeftPanel";
import Header from "../../components/Header";

import HomeLeftPanel from "../../components/HomeLeftPanel";
import StudentHomeLeftPanel from "../../components/StudentHomeLeftPanel";

import TopicCard from "../../components/cards/TopicCard";

import { UserAuth } from "../../context/AuthContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from 'react-router-dom'; // for routing
import { useParams } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import SubjectCard from "../curriculum/components/SubjectCard";

function Student() {
    const { logOut, user } = UserAuth();
    let { studentId } = useParams();
    const [childId, setChildId] = useState(studentId);
    const [curriculum, setCurriculum] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [studentName, setStudentName] = useState("");
    const [scheduleData, setScheduleData] = useState([]);


    useEffect(() => {
        const fetchCurriculum = async () => {
            try {
                const response = await axios.get(`/api/get-child/${childId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-tokens": user?.accessToken,
                    },
                });

                // Safely access the curriculum data
                const childData = response?.data?.child;
                const curriculums = childData?.curriculums;

                if (curriculums) {
                    // console.log(curriculums);
                    setCurriculum(curriculums.grades);
                    setStudentName(childData.name);

                    // Function to extract all lessons
                    const extractLessons = (curriculumData) => {
                        let lessons = [];
                        let lessons2 = [];

                        Object.keys(curriculumData).forEach((gradeId) => {
                            const subjects = curriculumData[gradeId].subjects;

                            // console.log(subjects)
                            Object.keys(subjects).forEach((subjectId) => {
                                const chapters = subjects[subjectId].chapters;
                                // console.log(chapters);

                                Object.keys(chapters).forEach((chapterId) => {
                                    const lessonsInChapter = chapters[chapterId].lessons;
                                    // console.log("lessonsInChapter", lessonsInChapter);

                                    Object.keys(lessonsInChapter).forEach((lessonId) => {
                                        const lesson = lessonsInChapter[lessonId];
                                        // console.log("lesson", lesson.name);
                                        // lessons2.push({
                                        //     title: lesson.name
                                        // })
                                        lessons2.push({
                                            id: lesson.id,
                                            title: lesson.name,
                                            start: lesson.start,
                                            end: lesson.end,
                                            order: lesson.order,
                                            chapterId: chapterId,
                                            subjectId: subjectId,
                                            gradeId: gradeId,
                                            lessonId: lesson.id,
                                            chapterTitle: chapters[chapterId].name,
                                            subjectTitle: subjects[subjectId].name
                                        });
                                    });
                                });
                            });
                        });


                        return lessons2;
                    };

                    const lessons = extractLessons(curriculums.grades);
                    // console.log(lessons)

                    setScheduleData(lessons);







                } else {
                    console.warn("Invalid or missing curriculum data:", curriculums);
                    setCurriculum(null);
                }
            } catch (err) {
                console.error("Error fetching curriculum:", err);
                setError(err?.response?.data?.message || "Failed to fetch curriculum");
            } finally {
                setLoading(false);
            }
        };

        fetchCurriculum();
    }, [user, childId]);





    const navigate = useHistory();
    // const scheduleData = [
    //     { id: 1, subject: 'Math', title: 'Introduction to Fractions', start: '2024-10-31T10:00:00', end: '2024-10-31T12:00:00', },
    //     { id: 2, subject: 'Science', title: 'Plant Life Cycle', start: '2024-10-31T12:30:00', end: '2024-10-31T14:00:00', },

    // ];

    const groupLessonsByDate = (lessons) => {
        return lessons.reduce((acc, lesson) => {
            // Ensure that lesson.start is defined and is a string
            if (lesson.start && typeof lesson.start === 'string') {
                const startDate = lesson.start.split('T')[0]; // Extract the date part (YYYY-MM-DD)
                if (!acc[startDate]) {
                    acc[startDate] = [];
                }
                acc[startDate].push(lesson);
            } else {
                console.error('Invalid lesson start date:', lesson);
            }
            return acc;
        }, {});
    };

    // Group the lessons by start date
    //   const groupedLessons = groupLessonsByDate(data);

    // Helper function to group lessons by subject
    const groupLessonsBySubject = (data) => {
        const grouped = data.reduce((acc, lesson) => {
            const subject = lesson.subject;
            if (!acc[subject]) {
                acc[subject] = [];
            }
            acc[subject].push(lesson);
            return acc;
        }, {});

        return grouped;
    };

    // Helper function to get a readable date label
    const getDateLabel = (date) => {
        const parsedDate = parseISO(date);
        if (isToday(parsedDate)) return "Today's Lessons";
        if (isYesterday(parsedDate)) return "Yesterday's Lessons";
        if (isTomorrow(parsedDate)) return "Tomorrow's Lessons";
        return format(parsedDate, 'EEEE, do MMMM yyyy');
    };

    const groupedLessons = groupLessonsByDate(scheduleData);
    // Get sorted dates in ascending order
    const sortedDates = Object.keys(groupedLessons).sort((a, b) => new Date(a) - new Date(b));




    const handleEventClick = (info) => {
        const eventId = info.event.id;
        navigate.push(`/study/${eventId}`);
    };
    const handleCardClick = (id) => {
        navigate.push(`/study/${id}`);
    };
    const handleDateClick = (info) => alert(`Date clicked: ${info.dateStr}`);


    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    // if (!curriculum) return <div>No curriculum found for this child.</div>;

    return (
        <>
            <div id="right-panel" className="right-panel" style={{ backgroundColor: "#fefefe" }}>
                <div className="content">
                    <div className="animated fadeIn">
                        <Header />
                        <StudentHomeLeftPanel currentPage={"curriculum"} studentName={studentName} studentId={studentId} />



                        {!loading && curriculum && (<div className="row" >
                            <div className={"col-md-12"} >


                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // marginBottom: "5px",
                                    }}
                                >
                                    <span style={{ fontFamily: "Acme, cursive", fontSize: "25px", }}>{studentName}'s Curriculum</span>
                                    {/* <i
                                        className="menu-icon fa fa-plus"
                                        style={{ fontSize: "15px", color: "gray", cursor: "pointer", justifySelf:'flex-end' }}
                                        title="Add New Subject"
                                    ></i> */}

                                </div>
{/* <img src={`https://image.pollinations.ai/prompt/a banana`} style={{height:'500px', width:'500px'}}/> */}

                                <hr />
                                {/* <br/> */}
                                {Object.entries(curriculum || {}).map(([gradeId, gradeData]) => (
                                    <div key={gradeId}>
                                        {Object.entries(gradeData?.subjects || {})
                                            .sort(([, a], [, b]) => a.order - b.order) // Sort subjects by order
                                            .map(([subjectId, subjectData]) => (
                                                <div key={subjectId} style={{}}>
                                                    <SubjectCard
                                                        studentId={studentId}
                                                        gradeId={gradeId}
                                                        subjectData={subjectData}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                ))}
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        </>




    );
}

const styles = {
    container: {
        maxWidth: '90%',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    subjectContainer: {
        marginBottom: '20px',
        padding: '15px',
        borderRadius: '5px',
        backgroundColor: '#e3eaf2',
    },
    subjectHeader: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#555',
    },
    time: {
        fontSize: '14px',
        color: '#777',
        marginBottom: '10px',
    },
    contentContainer: {
        marginBottom: '10px',
        padding: '10px',
        borderRadius: '4px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
    },
    contentTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#333',
    },
    contentDescription: {
        fontSize: '14px',
        color: '#666',
    },
    dueDate: {
        fontSize: '13px',
        fontStyle: 'italic',
        color: '#d9534f',
    },
    extraSection: {
        marginTop: '30px',
        padding: '15px',
        borderRadius: '5px',
        backgroundColor: '#f2f2f2',
    },
    extraHeader: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#555',
        marginBottom: '10px',
    },
    event: {
        backgroundColor: '#4A90E2',
        border: 'none',
        color: 'white',
        padding: '5px',
        borderRadius: '4px',
        fontSize: '0.9em',
        textAlign: 'center',
        fontWeight: '500',
    },
    dayCell: {
        backgroundColor: '#ffffff', // White day cells
        border: '1px solid #e5e5e5',
    },
    todayCell: {
        backgroundColor: '#eaf6ff', // Light blue for today
    },
    timeGridSlot: {
        color: '#333',
        fontWeight: 'bold',
    },
    timeGridEvent: {
        backgroundColor: '#4A90E2',
        borderRadius: '4px',
        color: '#ffffff',
    },

};

export default Student;
