import { useState, useEffect } from "react";
import { format, parseISO, isToday, isYesterday, isTomorrow, compareAsc } from 'date-fns';
import axios from "axios";
// import LeftPanel from "../../components/LeftPanel";
import Header from "../../components/Header";

import HomeLeftPanel from "../../components/HomeLeftPanel";

import TopicCard from "../../components/cards/TopicCard";

import { UserAuth } from "../../context/AuthContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from 'react-router-dom'; // for routing

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useParams } from "react-router-dom";
import TextTranslator from "./tools/TextTranslator";
import TextLeveler from "./tools/TextLeveler";
import TextProofreader from "./tools/TextProofreader";
import CreateLesson from "./tools/CreateLesson";
import TextRewriter from "./tools/TextRewriter";
import YouTubeQA from "./tools/YouTubeQA";
import YTQuizmaker from "./tools/YTQuizmaker";
import WorksheetGenerator from "./tools/WorksheetGenerator";
import  WhyStudyThis from "./tools/WhyStudyThis";
import  LearnByDoing from "./tools/LearnByDoing";
import  YTVideoSummarizer from "./tools/YTVideoSummarizer";
import  CommonMisconceptions from "./tools/CommonMisconceptions";
import  UnlockFunFacts from "./tools/UnlockFunFacts";
import  QuoteOfTheDay from "./tools/QuoteOfTheDay";
import  DefinitionGenerators from "./tools/DefinitionGenerators";
import GroupWorkGenerator from "./tools/GroupWorkGenerator";
import GlossaryGenerator from "./tools/GlossaryGenerator";
import FlashcardGenerator from "./tools/FlashcardGenerator";





function AiTool() {
    const navigate = useHistory();
    const { logOut, user } = UserAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [students, setStudents] = useState([]);
    let { toolId } = useParams();


    // Map of tool IDs to components
    const toolComponents = {
        'text-translator': <TextTranslator />,
        'text-leveler': <TextLeveler />,
        'text-proofreader': <TextProofreader/>,
        'create-lesson' : <CreateLesson/>,
        'text-rewriter': <TextRewriter/>,
        'youtube-chat': <YouTubeQA/>,
        'youtube-quizmaker': <YTQuizmaker/>,
        'worksheet-generator': <WorksheetGenerator/>,
        'why-study-this': <WhyStudyThis/>,
        'learn-by-doing': <LearnByDoing/>,
        'yt-video-summarizer': <YTVideoSummarizer/>,
        'common-misconceptions': <CommonMisconceptions/>,
        'unlock-fun-facts': <UnlockFunFacts/>,
        'quote-of-the-day': <QuoteOfTheDay/>,
        'definition-generators': <DefinitionGenerators/>,
        'glossary-generator': <GlossaryGenerator/>,
        'flashcard-generator': <FlashcardGenerator/>,

    };

    const RenderedComponent = toolComponents[toolId] || (
        <div>Tool not found or unsupported.</div>
    );

    return (
        <>
            <div id="right-panel" className="right-panel" style={{ backgroundColor: "white" }}>
                <div className="content">
                    <div className="animated fadeIn">
                        <Header />
                        <HomeLeftPanel currentPage={"topics"} />

                        <div >
                            <div style={{ padding: "0px" }}>
                            {RenderedComponent}
                            </div>

                        </div>




                    </div>
                </div>
            </div>
        </>




    );
}

const styles = {
    container: {
        maxWidth: '90%',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    subjectContainer: {
        marginBottom: '20px',
        padding: '15px',
        borderRadius: '5px',
        backgroundColor: '#e3eaf2',
    },
    subjectHeader: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#555',
    },
    time: {
        fontSize: '14px',
        color: '#777',
        marginBottom: '10px',
    },
    contentContainer: {
        marginBottom: '10px',
        padding: '10px',
        borderRadius: '4px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
    },
    contentTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#333',
    },
    contentDescription: {
        fontSize: '14px',
        color: '#666',
    },
    dueDate: {
        fontSize: '13px',
        fontStyle: 'italic',
        color: '#d9534f',
    },
    extraSection: {
        marginTop: '30px',
        padding: '15px',
        borderRadius: '5px',
        backgroundColor: '#f2f2f2',
    },
    extraHeader: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#555',
        marginBottom: '10px',
    },
    event: {
        backgroundColor: '#4A90E2',
        border: 'none',
        color: 'white',
        padding: '5px',
        borderRadius: '4px',
        fontSize: '0.9em',
        textAlign: 'center',
        fontWeight: '500',
    },
    dayCell: {
        backgroundColor: '#ffffff', // White day cells
        border: '1px solid #e5e5e5',
    },
    todayCell: {
        backgroundColor: '#eaf6ff', // Light blue for today
    },
    timeGridSlot: {
        color: '#333',
        fontWeight: 'bold',
    },
    timeGridEvent: {
        backgroundColor: '#4A90E2',
        borderRadius: '4px',
        color: '#ffffff',
    },

};

export default AiTool;
