import React, { useState } from 'react';
import axios from "axios";
import { PuffLoader } from "react-spinners";

const LearnByDoing = () => {
    const [chatInput, setChatInput] = useState('');
    const [history, setHistory] = useState([]);
    
    {/* TODO: Update Here */}
    const [formData, setFormData] = useState({
        grade: '',
        message: '',
        context: '',

    });
    
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const { grade, message, context} = formData;

        {/* TODO: Update Here */}
        const newHistory = [
            ...history,
            {
                role: 'user',
                parts: `Suggest practical, real-life tasks or projects to inspire a homeschool student of Grade {grade} to "learn by doing," based on the following topic or study material.
Design tasks that are hands-on, age-appropriate, and feasible, focusing on experiential learning.
Suggest activities that start with basic, tangible actions and progress toward complex projects for deeper engagement.
Align suggestions with the context or homeschool student’s preferences if provided.
Ensure each task fosters curiosity and connects the topic to real-world applications.
Format the suggestions in HTML for easy presentation and use.
Topic or study material:
 {message}
Context or preferences (if given):
 {context}

                        
                `,
            },
        ];
        setHistory(newHistory);
        setFormSubmitted(true);

        try {
            const response = await axios.post("/api/sendChat", {
                history: newHistory,
            });

            const modelResponse = response.data.model;

            setHistory([
                ...newHistory,
                { role: 'model', parts: modelResponse },
            ]);
        } catch (error) {
            console.error('Error sending chat:', error);
        }
    };

    const handleSubmitChat = async (e) => {
        e.preventDefault();
        setChatInput('');
        if (chatInput.trim()) {
            const newHistory = [
                ...history,
                { role: 'user', parts: chatInput },
            ];
            setHistory(newHistory);

            try {
                const response = await axios.post("/api/sendChat", {
                    history: newHistory,
                });

                const modelResponse = response.data.model;

                setHistory([
                    ...newHistory,
                    { role: 'model', parts: modelResponse },
                ]);
            } catch (error) {
                console.error('Error sending chat:', error);
            }

            setChatInput('');
        }
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div style={styles.container}>
            {/* TODO: Update Here */}
            <h1 style={styles.header}>Learn By Doing</h1>
            <p style={styles.description}>
           Generate some real life tasks or projects based on a particular lesson.
            </p>


            {!formSubmitted ? (
                <div style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: "20px", borderRadius: '5px' }}>
                    <form onSubmit={handleFormSubmit} style={styles.form}> 
                        {/* TODO: Update Here */}
                        <label style={styles.label}>Grade level*:</label>
                        <select
                            name="grade"
                            value={formData.grade}
                            onChange={handleFormChange}
                            required
                            style={styles.input}
                        >
                            <option value="">Select Grade</option>
                            {["Grade 1", "Grade 2", "Grade 3", "Grade 4", "Grade 5", "Grade 6", "Grade 7", "Grade 8", "Grade 9", "Grade 10", "Grade 11", "Grade 12"].map((grade) => (
                                <option key={grade} value={grade}>
                                    {grade}
                                </option>
                            ))}
                        </select>
                        {/*<label style={styles.label}>Question Type*:</label>
                        <select
                            name="Qtype"
                            value={formData.Qtype}
                            onChange={handleFormChange}
                            required
                            style={styles.input}
                        >
                            <option value="">Select Question type</option>
                            {["Multiple Choice", "Free Response", "True/False"].map((Qtype) => (
                                <option key={Qtype} value={Qtype}>
                                    {Qtype}
                                </option>
                            ))}
                        </select>*/}
                        {/*<label style={styles.label}>Number of Questions*:</label>
                        <select
                            name="Qnumber"
                            value={formData.Qnumber}
                            onChange={handleFormChange}
                            required
                            style={styles.input}
                        >
                            <option value="">Select</option>
                            {["3", "5", "7","10"].map((Qnumber) => (
                                <option key={Qnumber} value={Qnumber}>
                                    {Qnumber}
                                </option>
                            ))}
                        </select>*/}

                        {/* <label style={styles.label}>Example Input type field *:</label>
                        <input
                            type="text"
                            name="someId"
                            value={formData.language}
                            onChange={handleFormChange}
                                required
                            style={styles.input}
                        /> */}

                        <label style={styles.label}>Topic or lesson title*:</label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleFormChange}
                            required
                            style={styles.textarea}
                        />
                        <label style={styles.label}>Additional context or preferences(optional):</label>
                        <textarea
                            name="context"
                            value={formData.context}
                            onChange={handleFormChange}
                            style={styles.textarea}
                        />
                        <button type="submit" style={styles.button}>
                            Generate
                        </button>
                    </form>
                </div>
            ) : (
                <div style={styles.chatContainer}>
                    <div style={{ marginBottom: "200px" }}>
                        {history.length <= 1 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                            <PuffLoader color="#ff602e" loading={true} size={25} />
                        </div>}
                        {history.slice(1).map((message, index) => (
                            <div
                                key={index}
                                style={{
                                    ...styles.message,
                                    backgroundColor: message.role === 'user' ? '#d1e7ff' : '#d4edda',

                                }}
                            >
                                <strong>{message.role === 'user' ? 'You' : 'Edusina'}:</strong>
                                <div dangerouslySetInnerHTML={{ __html: message.parts }} />
                            </div>
                        ))}
                    </div>
                    <form onSubmit={handleSubmitChat} style={{
                        ...styles.chatInputContainer,
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        transform: 'translateX(30%)',
                        width: '70%',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        backgroundColor: '#fff',
                        padding: '10px',
                        boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
                        zIndex: 1000,

                    }}>
                        <input
                            type="text"
                            value={chatInput}
                            onChange={(e) => setChatInput(e.target.value)}
                            placeholder="Type a message..."
                            required
                            style={styles.chatInput}
                        />
                        <button type="submit" style={styles.button}>
                            Send
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        fontFamily: "'Acme', sans-serif",
        margin: "0 auto",
        padding: "20px",
        maxWidth: "100%",
        // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    header: {
        fontSize: "2.5rem",
        textAlign: "center",
        marginBottom: "20px",
        fontFamily: "'Acme', sans-serif",
    },
    description: {
        textAlign: "center",
        marginBottom: "30px",
        fontSize: "1.2rem",
        fontFamily: "'Acme', sans-serif",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    },
    label: {
        fontSize: "1.2rem",
        marginBottom: "10px",
    },
    textarea: {
        width: "100%",
        height: "200px",
        padding: "10px",
        fontSize: "1rem",
        border: "1px solid #ccc",
        borderRadius: "5px",
    },
    button: {
        padding: "10px 20px",
        backgroundColor: "#000",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        fontSize: "1rem",
        cursor: "pointer",
    },
    chatContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "5px",

    },
    message: {
        padding: "30px",
        borderRadius: "5px",
        fontSize: "1rem",
        margin: "3px"
    },
    chatInputContainer: {
        display: "flex",
        gap: "10px",
        marginTop: "20px",
    },
    chatInput: {
        flex: 1,
        padding: "10px",
        fontSize: "1rem",
        borderRadius: "5px",
        border: "1px solid #ccc",
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '16px',
    }
};

export default LearnByDoing;
