import React, { useState, useEffect } from 'react';
import axios from "axios";
import { PuffLoader } from "react-spinners";
import LessonContent from './LessonContent';
import TeachingGuideline from './TeachingGuideline';
import Assignments from './Assignments';
import Resources from './Resources';


const Quiz = () => <div style={{ padding: "20px" }}>This is the Quiz.</div>;
// const Resources = ({ resources }) => (
//     <div style={{ padding: "20px" }}>
//         <h3>Resources</h3>
//         <ul>
//             {resources.map((resource, index) => (
//                 <li key={index}>{resource}</li>
//             ))}
//         </ul>
//     </div>
// );


const Lesson = ({ userId, childId, gradeId, subjectId, chapterId, lessonId }) => {
    // console.log("UUID::", userId);
    const [lessonData, setLessonData] = useState(null);

    useEffect(() => {
        const fetchLesson = async () => {
            try {
                const response = await axios.get(
                    `/api/lesson/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-tokens": userId,
                        },
                    }
                );
                setLessonData(response.data);
                console.log("lessonDATA:", response.data);
            } catch (err) {
                console.error(err.message || "An error occurred while fetching the lesson.");
            }
        };

        fetchLesson();
    }, [childId, gradeId, subjectId, chapterId, lessonId, userId]);

    const [activeTab, setActiveTab] = useState(0);

    const tabs = ["Lesson Content", "Teaching Guideline", "Assessment", "Resources"];

    // Components with associated props
    const tabComponents = [
        { Component: LessonContent, props: { userId, childId, gradeId, subjectId, chapterId, lessonId } },
        { Component: TeachingGuideline, props: { userId, childId, gradeId, subjectId, chapterId, lessonId } },
        // { Component: Quiz, props: {} },
        { Component: Assignments, props: { userId, childId, gradeId, subjectId, chapterId, lessonId } },
        { Component: Resources, props: { userId, childId, gradeId, subjectId, chapterId, lessonId } },
    ];

    // Get the active component and its props
    const { Component: ActiveComponent, props } = tabComponents[activeTab];




    return (
        <>
            {lessonData ? (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}>
                    <div style={{ display: "flex", gap: "1px", alignItems: "center", marginTop: "20px", }}>
                        {tabs.map((tab, index) => (
                            <span
                                key={index}
                                onClick={() => setActiveTab(index)}
                                style={{
                                    flexGrow: 1,
                                    padding: "2px",
                                    cursor: "pointer",
                                    textAlign:'center',
                                    borderRadius: "3px 3px 3px 3px",
                                    backgroundColor: activeTab === index ? "#000" : "#f0f0f0",
                                    color: activeTab === index ? "white" : "black",
                                    textDecoration: "none",
                                    fontWeight: activeTab === index ? "bold" : "normal",
                                    transition: "box-shadow 0.3s, background-color 0.3s, color 0.3s",
                                    boxShadow: "0 0 0px rgba(0, 0, 0, 0)",
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.boxShadow = `
                                    0 0 4px #ff0080, 
                                    0 0 8px #ffbf00, 
                                    0 0 12px #80ff00, 
                                    0 0 16px #00ffff, 
                                    0 0 20px #8000ff
                                    `;
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.boxShadow = "0 0 0px rgba(0, 0, 0, 0)";
                                }}
                            >
                                {tab}
                            </span>
                        ))}
                    </div>


                    {/* Render the active tab's content */}
                    <div style={{ padding: "20px", borderRadius: "0 0 2px 2px" }}>
                        <ActiveComponent {...props} />
                    </div>
                </div>

            ) : (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "50px",
                            padding: "5px",
                            borderRadius: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "black", // Ensures text is readable
                            fontFamily: "Acme, cursive", // Font style
                        }}
                    >
                        <PuffLoader color="#ff602e" loading={true} size={45} />
                        <span>Creating personalized content for your child with AI.</span>
                        <span>Please wait a momment...</span>
                    </div>


                </>
            )}
        </>
    );
};

export default Lesson;
