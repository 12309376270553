import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from "../../components/Header";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";
import StudentHomeLeftPanel from '../../components/StudentHomeLeftPanel';
import ChapterCard from './components/ChapterCard';

const Subject = () => {
    const { logOut, user } = UserAuth();
    const { childId, gradeId, subjectId } = useParams();
    const [subjectName, setSubjectName] = useState('');
    const [chapters, setChapters] = useState({});

    useEffect(() => {
        const fetchSubject = async () => {
            try {
                const response = await axios.get(`/api/child/${childId}/grade/${gradeId}/subject/${subjectId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-tokens": user?.accessToken,
                    },
                });
                console.log(response.data);
                setSubjectName(response.data.name);
                setChapters(response.data.chapters);

            } catch (err) {
                console.error("Error fetching subject:", err);
            }
        };

        fetchSubject();
    }, [user, childId, gradeId, subjectId]);

    const handleChapterDelete = (data) => {
        // Prompt confirmation before proceeding with the delete action
        const isConfirmed = window.confirm("Are you sure you want to delete this Topic?");
        if (!isConfirmed) {
            return; // If the user cancels, do nothing
        }

        // Destructure the necessary properties from the data object
        const { userId, studentId, gradeId, subjectId, chapterId } = data;

        // Construct the API endpoint
        const url = `/api/chapter/${studentId}/${gradeId}/${subjectId}/${chapterId}`;

        // Call the API using axios to delete the lesson
        axios
            .delete(url, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-tokens": userId, // Include the userId in the header
                },
            })
            .then((response) => {
                // Remove the deleted chapter from the chapters object
                const updatedChapters = { ...chapters };
                delete updatedChapters[chapterId];

                // Reorder the remaining chapters based on their 'order' field
                const reorderedChapters = Object.entries(updatedChapters)
                    .sort(([, a], [, b]) => a.order - b.order) // Sort chapters by order
                    .map(([key, chapter], index) => ({
                        ...chapter,
                        order: index + 1, // Reassign order starting from 1
                    }));

                // Update the chapters state with the reordered chapters
                const reorderedChaptersObject = reorderedChapters.reduce((acc, chapter) => {
                    acc[chapter.id] = chapter; // Using chapter id as the key
                    return acc;
                }, {});

                setChapters(reorderedChaptersObject); // Update state with the reordered chapters
            })
            .catch((error) => {
                console.error("Error deleting chapter:", error);
                // Optionally, handle the error (e.g., show an error message to the user)
            });
    };

    return (
        <>
            <div id="right-panel" className="right-panel" style={{backgroundColor:"white"}}>
                <div className="content">
                    <div className="animated fadeIn">
                        <Header />
                        <StudentHomeLeftPanel currentPage={"curriculum"} studentName={'studentName'} studentId={childId}/>

                        <div className="row">
                            <div className={"col-md-12"}>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                <span style={{ fontFamily: "Acme, cursive", fontSize: "25px", textAlign:"center" }}>{subjectName}</span>
                                
                                </div>
                                <hr/>
                                

                                {Object.entries(chapters || {})
                                    .sort(([, a], [, b]) => a.order - b.order) // Sort chapters by order
                                    .map(([chapterId, chapterData]) => (
                                        <div key={chapterId}>
                                            <ChapterCard
                                                userId={user.accessToken}
                                                studentId={childId}
                                                gradeId={gradeId}
                                                subjectId={subjectId}
                                                chapterData={chapterData}
                                                deleteCallback={handleChapterDelete}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Subject;
