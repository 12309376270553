import React, { useState } from "react";
import axios from "axios";
import AssignmentContent from "./assessmentComponents/AssignmentContent";
import QuizContent from "./assessmentComponents/QuizContent";
import FlashcardContent from "./assessmentComponents/FlashcardContent";

const ContentModal = ({ type, isOpen, onClose, ...props}) => {
    const [prompt, setPrompt] = useState("");
    const [generatedContent, setGeneratedContent] = useState(null);
    const [dueDate, setDueDate] = useState("");

    // const handleGenerate = async () => {
    //     try {
    //         const response = await axios.post(`/api/${type}/generate`, { prompt });
    //         setGeneratedContent(response.data);
    //     } catch (err) {
    //         console.error("Error generating content:", err.message);
    //     }
    // };

    // const handleSave = async () => {
    //     try {
    //         const payload = {
    //             userId,
    //             childId,
    //             gradeId,
    //             subjectId,
    //             chapterId,
    //             lessonId,
    //             content: generatedContent,
    //             dueDate,
    //         };
    //         await axios.post(`/api/${type}/save`, payload);
    //         alert(`${type.charAt(0).toUpperCase() + type.slice(1)} saved successfully!`);
    //         onClose();
    //     } catch (err) {
    //         console.error("Error saving content:", err.message);
    //     }
    // };

    const renderContent = () => {
        switch (type) {
            case "assignment":
                return <AssignmentContent {...props} />;
            case "quiz":
                return <QuizContent {...props} />;
            case "flashcard":
                return <FlashcardContent {...props} />;
            default:
                return <p>Unsupported content type.</p>;
        }
    };

    if (!isOpen) return null;

    return (
        <div  style={{paddingRight:'40px', paddingLeft:'40px'}}>
            <div style={{display:'flex', flexDirection:'column'}}>
                {/* <button  onClick={onClose} style={{width:'30px', alignSelf:'flex-end', backgroundColor:'black', color:'white',}}>
                    X
                </button> */}
                {renderContent()}
            </div>
        </div>
    );
};

export default ContentModal;
