import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import LandingPage from "./pages/landingPage/LandingPage";
import { AuthContextProvider } from "./context/AuthContext";
import Dashboard from "./pages/dashboard/Dashboard";
import AddNewStudent from "./pages/students/AddNewStudent";
import Student from "./pages/students/Student";
import Lesson from "./pages/students/Lesson";
import Home from "./pages/students/Home";
import ProtectedRoute from "./components/security/ProtectedRoute";
import Login from "./pages/landingPage/Login";
import AiToolBox from "./pages/aiToolBox/AiToolBox";
import AiTool from "./pages/aiToolBox/AiTool";
import Subject from "./pages/subject/Subject";
import Curriculum from "./pages/curriculum/Curriculum";

import { useEffect } from 'react';
import $ from 'jquery';

function App() {
  useEffect(() => {
    function handleResize() {
      const windowWidth = $(window).width();
      if (windowWidth < 1010) {
        $('body').addClass('small-device');
      } else {
        $('body').removeClass('small-device');
      }
    }

    $(window).on('load resize', handleResize);

    function handleMenuToggle(event) {
      event.stopPropagation(); // prevent the click event from propagating
      console.log('menu clicked'); // check if the function is being called

      const windowWidth = $(window).width();

      if (windowWidth < 1010) {
        $('body').removeClass('open');

        if (windowWidth < 760) {
          $('#left-panel').slideToggle();
        } else {
          $('#left-panel').toggleClass('open-menu');
        }
      } else {
        $('body').toggleClass('open');
        $('#left-panel').removeClass('open-menu');
      }
    }

    $(document).ready(() => {
      $('#menuToggle').on('mousedown', handleMenuToggle);

      $('.menu-item-has-children.dropdown').each(function () {
        $(this).on('mousedown', function () {
          const tempText = $(this).children('.dropdown-toggle').html();
          $(this).children('.sub-menu').prepend(`<li class="subtitle">${tempText}</li>`);
        });
      });
    });

    return () => {
      $(window).off('load resize', handleResize);
      $('#menuToggle').off('mousedown', handleMenuToggle);
      $('.menu-item-has-children.dropdown').off('mousedown');
    };
  }, []);
  return (
    <AuthContextProvider>
      <Router>
        <Switch>
          <Route path="/" component={LandingPage} exact />
          <Route path="/login" component={Login} exact />
          <ProtectedRoute path="/dashboard" component={Dashboard} exact />
          <ProtectedRoute path="/home" component={Home} exact />
          <ProtectedRoute path="/ai-toolbox" component={AiToolBox} exact />
          <ProtectedRoute path="/ai-tool/:toolId" component={AiTool} exact />
          <ProtectedRoute path="/add-new-student" component={AddNewStudent} exact />
          <ProtectedRoute path="/student/:studentId" component={Student} exact />
          <ProtectedRoute path="/schedule/:studentId" component={Curriculum} exact />
          <ProtectedRoute path="/lesson/:childId/:gradeId/:subjectId/:chapterId/:lessonId" component={Lesson} exact />
          <ProtectedRoute path="/subject/:childId/:gradeId/:subjectId" component={Subject} exact />
        </Switch>
      </Router>
    </AuthContextProvider>
  );
}

export default App;
