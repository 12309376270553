import { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header";
import HomeLeftPanel from "../../components/HomeLeftPanel";
import { UserAuth } from "../../context/AuthContext";
import { PuffLoader } from "react-spinners";

const AddNewStudent = () => {
  const { logOut, user } = UserAuth();
  const countryStateMap = {
    USA: [
      "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia",
      "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland",
      "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
      "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
      "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
    ],
    Canada: [
      "Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan",
      "Northwest Territories", "Nunavut", "Yukon"
    ]
  };

  const [formData, setFormData] = useState({
    name: "",
    grade: "",
    startDate: "",
    endDate: "",
    studyDays: [],
    schoolDayStartTime: "9:00 AM",
    schoolDayEndTime: "5:00 PM",
    country: "",
    state: "",
    subjects: [],
  });

  const [step, setStep] = useState(1); // Controls the current form step
  const [subjectsData, setSubjectsData] = useState([]); // Stores subjects data fetched from server
  const [selectedSubjects, setSelectedSubjects] = useState([]); // Stores selected subjects and chapters

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "country") {
      setFormData((prevData) => ({ ...prevData, state: "" })); // Reset state when country changes
    }
  };

  const handleStudyDaysChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => {
      const studyDays = checked
        ? [...prevData.studyDays, value] // Add the selected day
        : prevData.studyDays.filter((day) => day !== value); // Remove the deselected day

      return { ...prevData, studyDays };
    });
  };

  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    setFormData((prevData) => ({ ...prevData, state: selectedState }));

    const params = {
      country: formData.country,
      state: selectedState,
      grade: formData.grade,
    };
    console.log(params)

    try {
      const response = await axios.get("/api/ai-get-curriculum", { params });
      // setSubjectsData(response.data.subjects);
      console.log(response);
      setSubjectsData(response.data.curriculum);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  // Initialize selectedSubjects with all chapters selected by default
  useEffect(() => {
    if (subjectsData.length > 0) {
      const initialSelection = subjectsData.map((subject) => ({
        subject: subject.name,
        chapters: subject.chapters.map((chapter) => chapter.name), // select all chapters
      }));
      setSelectedSubjects(initialSelection);
    }
  }, [subjectsData]);

  const canProceedToNextStep = () => {
    if (step === 1) {
      return formData.name && formData.grade && formData.country && formData.state;
    }
    if (step === 3) {
      return formData.startDate && formData.endDate && formData.studyDays.length > 0;
    }
    return true;
  };

  const handleNext = () => {
    if (canProceedToNextStep()) {
      setStep(step + 1);
    }
  };

  const handleSubjectSelection = (subject) => {
    setSelectedSubjects((prev) => {
      const updated = prev.map((s) => ({ ...s })); // Avoid mutation
      const subjectIndex = updated.findIndex((s) => s.subject === subject);
      if (subjectIndex === -1) {
        // Add subject with all chapters selected
        const newSubject = {
          subject,
          chapters: subjectsData.find((s) => s.name === subject)?.chapters.map((chapter) => chapter.name) || [],
        };
        updated.push(newSubject);
      } else {
        // Toggle subject selection: deselect all chapters or select all
        const chapters = updated[subjectIndex].chapters.length
          ? [] // Deselect all chapters
          : subjectsData.find((s) => s.name === subject)?.chapters.map((chapter) => chapter.name) || []; // Select all chapters
        updated[subjectIndex].chapters = chapters;
      }
      return updated;
    });
  };

  const handleChapterSelection = (subject, chapter) => {
    setSelectedSubjects((prev) => {
      const updated = prev.map((s) => ({ ...s })); // Avoid mutation
      const subjectIndex = updated.findIndex((s) => s.subject === subject);
      if (subjectIndex === -1) {
        // Add new subject with selected chapter
        updated.push({
          subject,
          chapters: [chapter],
        });
      } else {
        const chapters = updated[subjectIndex].chapters;
        updated[subjectIndex].chapters = chapters.includes(chapter)
          ? chapters.filter((c) => c !== chapter) // Deselect chapter
          : [...chapters, chapter]; // Add chapter
      }
      return updated;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Format selected subjects and chapters into the desired structure
      const formattedSubjects = selectedSubjects.map((subject) => ({
        name: subject.subject,
        chapters: subject.chapters.map((chapterName) => {
          const chapter = subjectsData
            .find((s) => s.name === subject.subject)
            ?.chapters.find((c) => c.name === chapterName);
          return {
            name: chapter.name,
            lessons: chapter.lessons,
          };
        }),
      }));

      const updatedFormData = {
        ...formData,
        subjects: formattedSubjects, // Use formatted subjects structure
      };

      const response = await axios.post("/api/add-new-student/", updatedFormData, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": user?.accessToken,
        },
      });

      console.log("Response:", response.data);
      if (response.status === 200) {
        alert("Student added successfully!");
        setFormData({
          name: "",
          grade: "",
          startDate: "",
          endDate: "",
          studyDays: [],
          schoolDayStartTime: "",
          schoolDayEndTime: "",
          country: "",
          state: "",
          subjects: [],
        });
        setSelectedSubjects([]);
        setStep(1);
        window.location.href = '/home';
      } else {
        alert("Failed to add student.");
      }
    } catch (error) {
      console.error("Error adding student:", error);
      alert("An error occurred while adding the student.");
    }
  };



  const renderFormStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div style={styles.formGroup}>
              <label style={styles.label}>Student Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                style={styles.input}
              />
            </div>

            <div style={styles.formGroup}>
              <label style={styles.label}>Grade:</label>
              <select
                name="grade"
                value={formData.grade}
                onChange={handleInputChange}
                required
                style={styles.input}
              >
                <option value="">Select Grade</option>
                {["Grade 1", "Grade 2", "Grade 3", "Grade 4", "Grade 5", "Grade 6", "Grade 7", "Grade 8", "Grade 9", "Grade 10", "Grade 11", "Grade 12"].map((grade) => (
                  <option key={grade} value={grade}>
                    {grade}
                  </option>
                ))}
              </select>
            </div>

            <div style={styles.formGroup}>
              <label style={styles.label}>Country:</label>
              <select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                required
                style={styles.input}
              >
                <option value="">Select Country</option>
                {Object.keys(countryStateMap).map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>

            <div style={styles.formGroup}>
              <label style={styles.label}>{formData.country === "USA" ? "State" : "Province"}:</label>
              <select
                name="state"
                value={formData.state}
                onChange={handleStateChange}
                required
                style={styles.input}
                disabled={!formData.country}
              >
                <option value="">Select {formData.country === "USA" ? "State" : "Province"}</option>
                {formData.country &&
                  countryStateMap[formData.country].map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
              </select>
            </div>



          </>
        );

      case 3:
        return (
          <>
            <div style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: '50px' }}>

              <div style={styles.formGroup}>
                <label style={styles.label}>School Start Date:</label>
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  required
                  style={{ ...styles.input, width: "200px" }}
                />
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label}>School End Date:</label>
                <input
                  type="date"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleInputChange}
                  required
                  style={{ ...styles.input, width: "200px" }}
                />
              </div>
            </div>


            <div style={{ ...styles.formGroup, marginBottom: '50px' }}>
              <label style={styles.label}>Preferred Days of the Week:</label>
              <div style={{ display: 'flex', flexDirection: 'row', gap: "25px", }}>
                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                  <label key={day} style={{ marginBottom: '8px', }}>
                    <input
                      type="checkbox"
                      name="studyDays"
                      value={day}
                      checked={formData.studyDays.includes(day)}
                      onChange={handleStudyDaysChange}
                    />
                    <span style={{ marginLeft: "3px" }}>{day}</span>
                  </label>
                ))}
              </div>
            </div>

            <div style={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: '10px' }}>
              <div style={styles.formGroup}>
                <label style={styles.label}>School Day Start Time:</label>
                <select
                  name="schoolDayStartTime"
                  value={formData.schoolDayStartTime}
                  onChange={handleInputChange}
                  required
                  style={{ ...styles.input, width: "200px" }}
                >
                  {Array.from({ length: 48 }).map((_, index) => {
                    const hours = Math.floor(index / 2);
                    const minutes = index % 2 === 0 ? '00' : '30';
                    const period = hours < 12 ? 'AM' : 'PM';
                    const formattedHours = hours % 12 === 0 ? 12 : hours % 12; // Convert 24-hour to 12-hour format
                    const timeString = `${formattedHours}:${minutes} ${period}`;
                    return <option key={timeString} value={timeString}>{timeString}</option>;
                  })}
                </select>
              </div>

              <div style={styles.formGroup}>
                <label style={styles.label}>School Day End Time:</label>
                <select
                  name="schoolDayEndTime"
                  value={formData.schoolDayEndTime}
                  onChange={handleInputChange}
                  required
                  style={{ ...styles.input, width: "200px" }}
                >
                  {Array.from({ length: 48 }).map((_, index) => {
                    const hours = Math.floor(index / 2);
                    const minutes = index % 2 === 0 ? '00' : '30';
                    const period = hours < 12 ? 'AM' : 'PM';
                    const formattedHours = hours % 12 === 0 ? 12 : hours % 12; // Convert 24-hour to 12-hour format
                    const timeString = `${formattedHours}:${minutes} ${period}`;
                    return <option key={timeString} value={timeString}>{timeString}</option>;
                  })}
                </select>
              </div>
            </div>

          </>
        );

      case 2:
        return (
          <>
            {/* <h4 style={styles.header}>Your Curriculum</h4> */}
            <div style={styles.subjectList}>
              {subjectsData.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "50px",
                    padding: "5px",
                    borderRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "black", // Ensures text is readable
                    fontFamily: "Acme, cursive", // Font style
                  }}
                >
                  <PuffLoader color="#ff602e" loading={true} size={45} />
                  <p
                    className="card-text"
                    style={{ color: "black", marginLeft: "20px", fontFamily: "Acme, cursive", }}
                  >
                    Setting up your personalized curriculum with AI based on your selections. Please wait...
                  </p>
                </div>


              ) : (
                <>
                  <span style={{ fontFamily: "Acme", textAlign:"center"}}>We have curated the following subjects and their topics for your curriculum based on your answers. <br/>
                  Select your preferred subjects/topics now, or modify, add, or remove them anytime later to suit your needs. </span>

                  {subjectsData.map((subject) => (
                    <div key={subject.name} style={styles.subjectCard}>
                      <h5>
                        <input
                          type="checkbox"
                          id={`${subject.name}-subject`}
                          checked={selectedSubjects.some((s) => s.subject === subject.name && s.chapters.length > 0)}
                          onChange={() => handleSubjectSelection(subject.name)}
                          style={styles.checkbox}
                        />
                        <label htmlFor={`${subject.name}-subject`} style={{ ...styles.checkboxLabel, fontFamily: 'Acme', fontSize: '20px' }}>
                          {subject.name}
                        </label>
                      </h5>
                      {subject.chapters.map((chapter) => (
                        <div key={chapter.name} style={{ ...styles.checkboxContainer, marginLeft: "50px" }}>
                          <input
                            type="checkbox"
                            id={`${subject.name}-${chapter.name}`}
                            checked={selectedSubjects.some(
                              (s) => s.subject === subject.name && s.chapters.includes(chapter.name)
                            )}
                            onChange={() => handleChapterSelection(subject.name, chapter.name)}
                            style={styles.checkbox}
                          />
                          <label htmlFor={`${subject.name}-${chapter.name}`} style={{ ...styles.checkboxLabel, fontFamily: 'Acme', }}>
                            - {chapter.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  ))}


                </>
              )}
            </div>
          </>
        );

      default:
        return null;
    }
  };

  const renderProgression = () => {
    const steps = ["Enter Student Info", "Select Subjects & Topics", "Set Schedule"];
    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
        {steps.map((label, index) => (
          <div key={index} style={styles.progressStep}>
            <div
              style={{
                ...styles.stepCircle,
                backgroundColor: step === index + 1 ? "#4CAF50" : "#ccc",
              }}
            >
              {index + 1}
            </div>
            <span
              style={{
                ...styles.stepLabel,
                color: step === index + 1 ? "#4CAF50" : "#000",
                fontFamily: "Acme"
              }}
            >
              {label}
            </span>
          </div>
        ))}
      </div>
    );
  };


  return (
    <div id="right-panel" className="right-panel" style={{ backgroundColor: "white" }}>
      <div className="content">
        <div className="animated fadeIn">
          <Header />
          <HomeLeftPanel currentPage={"topics"} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center', // Vertically center
              alignItems: 'center',    // Horizontally center
              // height: '100vh',         // Full viewport height
            }}
          >
            <div style={{ fontFamily: "Acme", fontSize: "25px" }}>
              Add New Student
            </div>
            <div style={{ fontFamily: "Acme", fontSize: "15px", marginBottom: "20px" }}>
              Fill out a few details for us to get your school ready for the year!
            </div>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              {renderProgression()}
            </div>
            <div
              style={{
                width: '80%', // Form takes up 80% of the width
                // maxWidth: '800px', // Optional max width for larger screens
              }}
            >
              <form onSubmit={handleSubmit} style={styles.formContainer}>
                {renderFormStep()}
                <div style={styles.buttonContainer}>
                  {step > 1 && (
                    <button
                      type="button"
                      style={styles.button}
                      onClick={() => setStep(step - 1)}
                    >
                      Previous
                    </button>
                  )}
                  {step < 3 && (
                    <button
                      type="button"
                      style={{
                        ...styles.button,
                        display: canProceedToNextStep() ? "inline-block" : "none", // Only show button if valid to proceed
                      }}
                      onClick={handleNext}
                      disabled={!canProceedToNextStep()} // Disable button if form data is invalid
                    >
                      Next
                    </button>

                  )}
                  {step === 3 && (
                    <button type="submit" style={{... styles.button, display: canProceedToNextStep() ? "inline-block" : "none",}}>
                      Create Homeschool
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

const styles = {
  formContainer: {
    maxWidth: "100%",
    margin: "20px auto",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#fefefe",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#555",
    fontSize: "16px",
    fontFamily: "Acme"
  },
  input: {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "16px",
    fontFamily: "Acme"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "black",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    fontFamily: "Acme"
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
  subjectList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // marginLeft: "10px"
    // gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gap: "20px",
    // marginTop: "20px",
  },
  subjectCard: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  subjectCardHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#444",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  checkbox: {
    marginRight: "10px",
  },
  checkboxLabel: {
    fontSize: "14px",
    color: "#333",
  },
  progressStep: {
    textAlign: "center",
  },
  stepCircle: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "bold",
    margin: "0 auto 10px",
  },
  stepLabel: {
    fontSize: "14px",
  },
};

export default AddNewStudent;
