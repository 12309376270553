const SubjectCard = ({
  userId,
  studentId,
  gradeId,
  subjectData
}) => {
  const totalLessons = subjectData.chapters
    ? Object.values(subjectData.chapters).reduce(
        (total, chapter) => total + (chapter.lessons ? Object.keys(chapter.lessons).length : 0),
        0
      )
    : 0;

  const completedLessons = subjectData.chapters
    ? Object.values(subjectData.chapters).reduce(
        (completed, chapter) =>
          completed +
          (chapter.lessons
            ? Object.values(chapter.lessons).filter(
                (lesson) => lesson.isComplete === true
              ).length
            : 0),
        0
      )
    : 0;

  const handleCardClick = () => {
    window.location.href = `/subject/${studentId}/${gradeId}/${subjectData.id}`;
  };

  const handleIconClick = (e) => {
    e.stopPropagation(); // Prevent the click from propagating to the parent div
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "30px",
          padding: "10px",
          borderRadius: "10px", // Rounded corners
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Shadow around all sides
          color: "black", // Ensures text is readable
          fontFamily: "Acme, cursive", // Font style
          transition: "box-shadow 0.3s, background-color 0.3s, color 0.3s",
          cursor: "pointer", // Change cursor to pointer on hover
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.boxShadow = `
          0 0 4px #ff0080, 
          0 0 4px #ffbf00, 
          0 0 4px #80ff00, 
          0 0 16px #00ffff, 
          0 0 12px #ff00ff`;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.2)";
        }}
        onClick={handleCardClick} // Handle card click
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <b style={{ fontSize: "1.0em" }}>
            {subjectData.name}{" "}
            <span style={{ fontSize: "0.8em" }}>
              ({completedLessons} out of {totalLessons} lessons completed)
            </span>
          </b>

          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <i
              className="menu-icon fa fa-trash"
              style={{ fontSize: "15px", color: "gray", cursor: "pointer" }}
              title="Delete this Topic"
              onClick={(e) => {
                handleIconClick(e);
                // alert("Delete clicked!");
              }}
            ></i>
            <a
              href={`/subject/${studentId}/${gradeId}/${subjectData.id}`}
              style={{ textDecoration: "underline", fontSize: "0.8em" }}
              onClick={handleIconClick}
            >
              <i
                className="menu-icon fa fa-arrow-right"
                style={{ fontSize: "15px", color: "gray", cursor: "pointer" }}
                title="Open this Subject"
              ></i>
            </a>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <span style={{ fontSize: "0.8em", fontWeight: "bold", marginBottom: "4px" }}>
              Progress: {((completedLessons / totalLessons) * 100).toFixed(1)}%
            </span>
          </div>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "12px",
              borderRadius: "6px",
              backgroundColor: "#e0e0e0",
              overflow: "hidden",
              boxShadow: "inset 0 0 5px rgba(0,0,0,0.2)",
            }}
          >
            <div
              style={{
                width: `${(completedLessons / totalLessons) * 100}%`,
                height: "100%",
                background: "linear-gradient(90deg, #4caf50, #8bc34a)",
                transition: "width 0.3s ease",
              }}
            ></div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexGrow: 1,
            }}
          >
            <span style={{ marginRight: "5px" }}>Topics:</span>
            {Object.entries(subjectData?.chapters || {})
              .sort(([, a], [, b]) => a.order - b.order) // Sort chapters by order
              .map(([chapterId, chapterData]) => (
                <div
                  key={chapterId}
                  style={{
                    margin: "1px 2px",
                    padding: "2px 3px",
                    backgroundColor: "#fdfdfd",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    fontSize: "0.7em",
                    display: "inline-block",
                  }}
                >
                  {chapterData?.name || "Unnamed Chapter"}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubjectCard;
