import { useState, useEffect } from "react";
// import logo from "./assets/images/logo_picspanel.png";
import logo from './assets/images/edusinaIcons/Logo.png';
import logo2 from "./assets/images/edusinaIcons/Logo.png";
import admin from "./assets/images/admin.jpg";
import { UserAuth } from "../context/AuthContext";
import axios from "axios";
import edusina_icon from './assets/images/edusina-icon.jpg';

const Header2 = ({ topicId }) => {
  const { logOut, user } = UserAuth();
  const [profilePic, setProfilePic] = useState('');


  // const [onlineUsers, setOnlineUsers] = useState(0);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get('/api/online_users'); // Adjust the URL if needed
  //       setOnlineUsers(response.data.online_users);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData(); // Fetch data initially

  //   const intervalId = setInterval(() => {
  //     fetchData(); // Fetch data every 30 seconds
  //   }, 30000); // 30 seconds in milliseconds

  //   return () => {
  //     clearInterval(intervalId); // Clean up the interval on unmount
  //   };
  // }, []);


  useEffect(() => {
    // console.log("USER PHOTO URL:: ",user.photoURL);
    setProfilePic(user.photoURL);
  }, [user]);

  const handleSignOut = async () => {
    try {
      await logOut();
      // console.log("Loggged out");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <header id="header" className="header">
      <div className="top-left">
        <div className="navbar-header">
          <a className="navbar-brand" href="/home">
            {/* <b>EduSina</b> */}
            <img src={logo} alt="Logo" style={{ height: "35px" }} /> 
            {/* <div
              style={{
                marginRight: '2px',
                width: '7px',
                height: '7px',
                backgroundColor: 'green',
                borderRadius: '50%',
                display: 'inline-block',
                animation: 'blink 1s infinite alternate',
                animationName: 'blink',
                animationDuration: '1s',
                animationIterationCount: 'infinite',
                animationDirection: 'alternate'
              }}>
            </div> */}
            {/* <span style={{ fontSize: '11px', color: 'green' }} className="rounded-circle">{onlineUsers} Users Online</span> */}
          </a>
          <a className="navbar-brand hidden" href="/topics">
            <img src={logo2} alt="Logo" />
          </a>
          <a id="menuToggle" className="menutoggle">
            <i className="fa fa-bars" style={{ color: '#ff602e', visibility:"hidden" }}></i>
          </a>

        </div>
      </div>
      <div className="top-right">
        <div className="header-menu">
          <div className="header-left">
            <div className="form-inline">
              <form className="search-form">
                <input
                  className="form-control mr-sm-2"
                  type="text"
                  placeholder="Search ..."
                  aria-label="Search"
                />
                <button className="search-close" type="submit">
                  <i className="fa fa-close"></i>
                </button>
              </form>
            </div>
          </div>

          <div className="user-area dropdown float-right">
            <a
              href="#"
              className="dropdown-toggle active"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="user-avatar rounded-circle" style={{padding:'5px'}}
                src={user.photoURL}
                alt={user?.displayName || ''}
              />
            </a>

            <div className="user-menu dropdown-menu" >
              <a className="nav-link" href="#">
              {' '}
              </a>
              
              <a className="nav-link" href={`/topics`} >
                <i className="fa fa-arrow-left"></i>Back
              </a>
              <a className="nav-link" href={`#`} onClick={handleSignOut}>
                <i className="fa fa-power-off"></i>Log Out
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </header>
  );

};

export default Header2;
