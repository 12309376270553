import React, { useState, useEffect } from 'react';
import axios from "axios";
import { PuffLoader } from "react-spinners";
import ContextualChat from '../../aiToolBox/tools/ContextualChat';

const TeachingGuideline = ({ userId, childId, gradeId, subjectId, chapterId, lessonId }) => {
    const [lessonData, setLessonData] = useState(null);

    useEffect(() => {
        const fetchLesson = async () => {
            try {
                const response = await axios.get(
                    `/api/lesson/teaching-guideline/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-tokens": userId,
                        },
                    }
                );

                setLessonData(response.data);
                console.log("Teaching GUIDE:", response.data);
            } catch (err) {
                console.error(err.message || "An error occurred while fetching the lesson.");
            }
        };

        fetchLesson();
    }, [childId, gradeId, subjectId, chapterId, lessonId, userId]);


    return (
        <>
            {lessonData ? (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>

                    <div
                        style={{ padding: "40px" }}
                        dangerouslySetInnerHTML={{ __html: lessonData.teaching_guideline }}
                    />

                    <div>
                        <ContextualChat
                            context={lessonData.teaching_guideline}
                        />
                    </div>

                </div>

            ) : (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "50px",
                            padding: "5px",
                            borderRadius: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "black", // Ensures text is readable
                            fontFamily: "Acme, cursive", // Font style
                        }}
                    >
                        <PuffLoader color="#ff602e" loading={true} size={45} />
                        <span>Creating a teaching guideline for you!</span>
                        <span>Please wait a momment...</span>
                    </div>


                </>
            )}
        </>
    );
};

export default TeachingGuideline;
