import React, { useState } from "react";
import axios from "axios";
import { PuffLoader } from "react-spinners";

const QuizContent = ({ userId, childId, gradeId, subjectId, chapterId, lessonId, lessonData, onAssessmentUpdate }) => {
    // Helper function to get the default date
    const getDefaultDueDate = () => {
        const today = new Date();
        today.setDate(today.getDate() + 7); // Add 7 days
        return today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    };

    const [prompt, setPrompt] = useState("");
    const [dueDate, setDueDate] = useState(getDefaultDueDate());
    const [generatedContent, setGeneratedContent] = useState(null);
    const [inProgress, setInProgress] = useState(false);

    const handleGenerate = async () => {
        setInProgress(true);
        const history = [
            {
                role: 'user',
                parts: `Generate MCQ Quiz Questions based on the following Lesson Content. 
                        
                        ${prompt ? `Follow the instructions: ${prompt}` : ''}
        
                        Give the result in HTML format.
                        
                        Lesson Content:
                        ${lessonData}
                        `,
            },
        ];



        try {
            const response = await axios.post("/api/sendChat", {
                history: history,
            });
            setInProgress(false);
            const modelResponse = response.data.model;
            setGeneratedContent(modelResponse);

        } catch (error) {
            console.error('Error sending chat:', error);
        }
    };

    const handleSave = async () => {
        try {
            const payload = {
                type: "Quiz", // Modify as needed (e.g., "quiz", "flashcard")
                content: generatedContent,
                dueDate,
            };
    
            await axios.post(
                `/api/lesson/assessments/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-tokens": userId, // Include the userId in the header
                    },
                }
            );
            onAssessmentUpdate();
            setGeneratedContent(null);
            setPrompt('');    
            console.log("Assessment saved successfully!");
        } catch (err) {
            console.error("Error saving content:", err.message);
        }
    };
    

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '18px' }}>Quiz Prompt (Optional)</span>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input
                    placeholder="Example: generate 2 Quiz questions or make the questions easy etc ... (optional)"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    style={{ borderRadius: '5px', flexGrow: '1' }}
                />
                <button onClick={handleGenerate} style={{ backgroundColor: 'black', color: 'white', borderRadius: '5px', width: '100px', alignSelf: 'flex-start', margin: '3px' }}>Generate</button>
            </div>

            {generatedContent ? (<div style={{ display: 'flex', flexDirection: 'column', marginTop:'20px' }}>
                
                <div dangerouslySetInnerHTML={{ __html: generatedContent }} />
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px', justifyContent: 'flex-end' }}>
                    <label>
                        Set Due Date:
                        <input
                            type="date"
                            value={dueDate}
                            onChange={(e) => setDueDate(e.target.value)}
                        />
                    </label>
                    <button onClick={handleSave} style={{ backgroundColor: 'black', color: 'white', borderRadius: '5px', width: '150px', alignSelf: 'flex-start',  }}>Save Assignment</button>
                </div>
            </div>) : <></>}
            {inProgress ? (<div style={{ display: 'flex', flexDirection: 'column', marginTop:'20px', justifyContent:'center', alignItems:'center' }}>
                <PuffLoader color="#ff602e" loading={true} size={25} />
            </div>) : <></>}

        </div>
    );
};

export default QuizContent;
